import { useContext } from "react";
import { sanityClient, urlFor } from "../lib/Sanity";

import StoreContext from "../context/store-context";
import Hero from "../components/hero";
import BlogSlider from "../templates/sliders/BlogSlider";
import { NextSeo } from "next-seo";
import { getSpecials } from "./api/jane-specials";
import { getToken } from "./api/token";

import Specials from "../templates/specials/Specials";
import ProductSlider from "../templates/sliders/ProductSlider";

import BrandSlider from "../templates/sliders/BrandSlider";
import CategorySlider from "../templates/sliders/CategorySlider";
import WhyPurpleLotus from "../templates/sliders/WhyPurpleLotus";
import Head from "next/head";

export async function getServerSideProps({ req }) {
  const store = req.cookies["JANE_STORE"];
  let token, specials, shopCategories, heroImageData, posts;
  
  try {
    token = await getToken();
    specials = await fetchSpecials(store, token.access_token);
  } catch (error) {
    console.error("Failed to fetch specials:", error);
    specials = { productSpecials: [], bundleSpecials: [] };
  }

  const today = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    timeZone: "America/Los_Angeles",
  })
    .format(new Date())
    .toLowerCase();
  const enabledKey = `enabled_${today}`;

  const filterSpecialsByEnabledKey = (specials) => {
    return specials.filter((special) => special[enabledKey]);
  };

  try {
    shopCategories = await sanityClient.fetch(
      `*[_type == "shopCategories"]`
    );

    const query = `*[_type == "homepageHeader" && active == true] | order(_updatedAt desc)[0] {
      backgroundImage,
      mobileBackgroundImage
    }`;

    heroImageData = await sanityClient.fetch(query);
    posts = await sanityClient.fetch(
      `*[_type == "post"] | order(_createdAt desc) [0..6]{
        _id,
        title,
        slug,
        createdAt,
        categories[] -> {
          title
        },
        mainImage
      }`
    );
  } catch (error) {
    console.error("Failed to fetch content from Sanity:", error);
    shopCategories = [];
    heroImageData = {};
    posts = [];
  }

  const heroImageUrl = heroImageData?.backgroundImage
    ? urlFor(heroImageData.backgroundImage).width(1920).url()
    : '';
  const heroMobileImageUrl = heroImageData?.mobileBackgroundImage
    ? urlFor(heroImageData.mobileBackgroundImage).width(800).url()
    : urlFor(heroImageData.backgroundImage).width(800).url();

  return {
    props: {
      posts,
      shopCategories,
      bundleSpecials: filterSpecialsByEnabledKey(specials.bundleSpecials),
      productSpecials: filterSpecialsByEnabledKey(specials.productSpecials),
      heroImageUrl,
      heroMobileImageUrl,
    },
  };
}

// Utility function to fetch specials with error handling
async function fetchSpecials(store, accessToken) {
  const types = ["product", "bundle"];
  try {
    const requests = types.map((type) => getSpecials(store, type, accessToken));
    const responses = await Promise.all(requests);
    return {
      productSpecials: responses[0].data.specials,
      bundleSpecials: responses[1].data.specials,
    };
  } catch (error) {
    console.error("Failed to fetch specials:", error);
    throw error;
  }
}

export default function HomePage({
  posts,
  productSpecials,
  bundleSpecials,
  heroImageUrl,
  heroMobileImageUrl,
}) {

  return (
    <>
      <header>
        <Head>
          {heroImageUrl && (
            <link rel="preload" href={heroImageUrl} as="image" media="(min-width: 768px)" />
          )}
          {heroMobileImageUrl && (
            <link rel="preload" href={heroMobileImageUrl} as="image" media="(max-width: 767px)" />
          )}
          <link rel="preconnect" href="https://cdn.sanity.io" />
          <link rel="dns-prefetch" href="https://cdn.sanity.io" />
        </Head>
        <NextSeo
          title="Shop Cannabis Delivery and In-Store Pickup | Purple Lotus"
          description="Are you looking for a dispensary in San Jose, CA? Call (408)456-0420 or visit Purple Lotus for a comfortable atmosphere, and top-quality cannabis for every lifestyle."
          canonical="https://plpcsanjose.com"
          openGraph={{
            url: "https://plpcsanjose.com",
            title: "Shop Cannabis Delivery and In-Store Pickup | Purple Lotus",
            description:
              "Are you looking for a dispensary in San Jose, CA? Call (408)456-0420 or visit Purple Lotus for a comfortable atmosphere, and top-quality cannabis for every lifestyle.",
            images: [
              {
                url: "https://plpcsanjose.com/images/purple-lotus-dispensary-building.jpg",
                width: 800,
                height: 600,
                alt: "Purple Lotus Cannabis Dispensary",
                type: "image/jpeg",
              },
            ],
            siteName: "Purple Lotus",
          }}
        />

        <Hero heroImageUrl={heroImageUrl} heroMobileImageUrl={heroMobileImageUrl} />
      </header>
      <main>
        <section className="container-row flex-column gap-300">
          <Specials
            title="Deals"
            productSpecials={productSpecials}
            bundleSpecials={bundleSpecials}
            noIcon
          />
          <ProductSlider title="Sale" root_types="sale" noIcon />
          <CategorySlider title="Categories" />
          <BrandSlider title="brands" />
          <ProductSlider
            title="Blue Chip Flower"
            root_types="flower"
            brand="Blue Chip"
            noIcon
          />
        </section>
        <section className="row pb-3 flex-column gap-400" id="why">
          <WhyPurpleLotus />
          <BlogSlider posts={posts} />
        </section>
      </main>
    </>
  );
}
