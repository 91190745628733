import Link from "next/link";
import BlogCard from "../../components/blog/BlogCard";
import styles from "./BlogSlider.module.scss";
import { faCannabis, faFamilyPants, faCalendarDays, faAwardSimple, faUserHeadset, faStar, faTruckFast, faShieldCheck, faBadgeDollar } from '@fortawesome/pro-solid-svg-icons';
import { sanityClient, urlFor } from '../../lib/Sanity'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTurnDownRight, faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { Navigation, Pagination, FreeMode, Scrollbar } from "swiper";
import Button from "../../components/Button/button";


export default function BlogSlider({ posts }) {

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            <h2>Our Blog</h2>
          </div>
          <Button type="link" href='/blog' className="sliderLink" prefetch={false} ariaLabel="View All Blog Posts">
            <span>view all</span>
          </Button>
        </div>

        <Swiper
          modules={[Navigation, Pagination, FreeMode, Scrollbar]}
          className={styles.blogSwiper}
          touchEventsTarget='wrapper'
          breakpoints={{
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 14,
            },
            550: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 1.2,
              slidesPerGroup: 1,
              spaceBetween: 10,
            }
          }}

          navigation={{
            prevEl: `#blog-prev`,
            nextEl: `#blog-next`,
            clickable: true,
          }}
        >
          <div className={styles.prev} id={`blog-prev`}>
            <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </div>
          <div className={styles.next} id={`blog-next`}>
            <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>

          {posts?.map((post, index) =>
            <SwiperSlide key={`blogPost-${index}`} className={styles.slide}>
              <BlogCard
                image={urlFor(post.mainImage).width(454).height(227).url()}
                title={post.title}
                category={post.categories[0].title}
                url={post.slug.current}
                id={post._id}
              />
            </SwiperSlide>
          )}


        </Swiper>
      </div>
    </>

  );

}

