import { useRef, useState } from "react";
import { sanityClient, urlFor } from "../../lib/Sanity";
import Link from "next/link";
import styles from "./Hero.module.scss";
import Skeleton from "../skeletons/SkeletonHero";
import Image from "next/image";
import UseMediaQuery from "../../hooks/useMediaQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper";

import useSWR from "swr";

const fetcher = async () => {
  const query = `*[_type == "homepageHeader" && active == true] | order(_updatedAt desc)`;
  return sanityClient.fetch(query);
};

export default function HomepageHero({ heroImageUrl, heroMobileImageUrl }) {
  const isBreakPoint = UseMediaQuery(768);
  // const progressCircle = useRef(null);
  // const progressContent = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);

  const { data, error } = useSWR("header", fetcher);
  if (error) return <div>Failed to load header</div>;
  if (!data) return <Skeleton />;

  return (
    <div className={styles.hero}>
      <div id="hero-prev" className={styles.prev}>
        <FontAwesomeIcon icon={faArrowLeft} aria-label="previous slide" />
      </div>
      <Swiper
        onSwiper={setSwiperRef}
        spaceBetween={0}
        slidesPerView={1}
        modules={[Pagination, Navigation, Autoplay]}
        keyboard={{
          enabled: true,
          onlyInViewport: false,
        }}
        pagination={{
          clickable: true,
          el: `#hero-pagination`,
        }}
        navigation={{
          prevEl: `#hero-prev`,
          nextEl: `#hero-next`,
          clickable: true,
        }}
        loop={true}
      >

        <SwiperSlide key="first-slide" className={styles.swiperSlide}>
          <div className={styles.overlay}>
            <Image
              alt="Background image for first slide"
              src={isBreakPoint ? heroMobileImageUrl : heroImageUrl}
              fill={true}
              sizes="(max-width: 1600px) 100vw, 1600px"
              priority={true}
              placeholder="blur"
              blurDataURL="data:image/jpeg;base64,[small_base64_image]"
            />
       
              <div className={`${styles.hero__content__wrapper} container`}>
                <div className={styles.hero__content}>
                  <h1 className={styles.hero__title}>{data[0].title}</h1>
                  <p className={styles.hero__body}>{data[0].body}</p>
                  {data[0].buttonUrl && (
                    <Link
                      href={data[0].buttonUrl || "/"}
                      className="button"
                      data-type="primary"
                    >
                      {data[0].buttonText}
                    </Link>
                  )}
                </div>
              </div>
          
          </div>
        </SwiperSlide>

        {data?.slice(1).map((slide, index) => (
          <SwiperSlide key={slide.title} className={styles.swiperSlide}>
            <div className={styles.overlay}>
              <Image
                alt={`Background image for slide number ${index + 2}`}
                src={
                  !isBreakPoint
                    ? urlFor(slide.backgroundImage).width(1600).quality(80).url()
                    : slide.mobileBackgroundImage
                      ? urlFor(slide.mobileBackgroundImage).width(800).quality(80).url()
                      : urlFor(slide.backgroundImage).width(800).quality(80).url()
                }
                fill={true}
                sizes="(max-width: 1600px) 100vw, 1600px"
                loading="lazy" 
                placeholder="blur"
                blurDataURL="data:image/jpeg;base64,[small_base64_image]"
                className="swiper-lazy-preloader"
              />
              <div className={`${styles.hero__content__wrapper} container`}>
                <div className={styles.hero__content}>
                  <h1 className={styles.hero__title}>{slide.title}</h1>
                  <p className={styles.hero__body}>{slide.body}</p>

                  {slide.buttonUrl && (
                    <Link
                      href={slide.buttonUrl || "/"}
                      className="button"
                      data-type="primary"
                    >
                      {slide.buttonText}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div id="hero-pagination" className={styles.bullets}></div>
      </Swiper>
      <div id="hero-next" className={styles.next}>
        <FontAwesomeIcon icon={faArrowRight} aria-label="next slide" />
      </div>
    </div>
  );
}
