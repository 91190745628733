import Link from "next/link";
import Card from "../../components/card";
import styles from "./WhyPurpleLotus.module.scss";
import React, { useState, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTurnDownRight, faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { Navigation, Pagination, FreeMode, Scrollbar } from "swiper";

import family from "../../public/icons/animated/family.json";
import calendar from "../../public/icons/animated/calendar.json";
import verified from "../../public/icons/animated/verified.json";
import deliveryTruck from "../../public/icons/animated/delivery-truck.json";
import shield from "../../public/icons/animated/shield.json";
import information from "../../public/icons/animated/information.json";
import priceTag from "../../public/icons/animated/tag.json";
import support from "../../public/icons/animated/support.json";
// import support from "../../public/icons/animated/question.json";
import marijuana from "../../public/icons/animated/marijuana.json";
import Button from "../../components/Button/button";

export default function WhyPurpleLotus() {
  const [loadFamilyIcon, setLoadFamilyIcon] = useState(false);

  const familyIconRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // If the element is in the viewport and the icon isn't already loaded, set it to load
      if (entries[0].isIntersecting && !loadFamilyIcon) {
        setLoadFamilyIcon(true);
      }
    });

    if (familyIconRef.current) {
      observer.observe(familyIconRef.current);
    }

    return () => {
      if (familyIconRef.current) {
        observer.unobserve(familyIconRef.current);
      }
    };
  }, [loadFamilyIcon]);

  return (
    <div className={styles.container}>
      <div className={styles.rowHeader}>
        <h2>Live, <span>Love, </span><span>Lotus.</span></h2>
      </div>


      <Swiper
        modules={[Navigation, Pagination, FreeMode, Scrollbar]}
        className={styles.whySwiper}
        spaceBetween={15}
        touchEventsTarget='wrapper'
        breakpoints={{
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 14,
          },
          550: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 10,
          }
        }}
        navigation={{
          prevEl: `#why-prev`,
          nextEl: `#why-next`,
          clickable: true,
        }}
      >
        <div className={styles.prev}>
          <Button type="button" id={`why-prev`} className="slider-arrow" ariaLabel="Previous Slide">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </div>
        <div className={styles.next}>
          <Button type="button" id={`why-next`} className="slider-arrow" ariaLabel="Next Slide">
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>

        <SwiperSlide className={styles.slide} ref={familyIconRef}>
          <Card
            title="Family-owned & Operated"
            animatedIcon={loadFamilyIcon ? family : null} // Load the Lottie animation only if loadFamilyIcon is true
            link="About us"
            src="/about"
            gap="0.5"
          >
            We are a family-owned dispensary, founded in 2010. When you visit us, we treat you like family.
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Open 365 Days"
            // icon={faCalendarDays}
            animatedIcon={calendar}
            link="Locations &amp; Hours"
            src="/pickup"
            gap="0.5"

          >
            Our dispensary, pickup, and delivery services are available everyday of the year, including holidays.
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Hundreds of Products"
            // icon={faCannabis}
            animatedIcon={marijuana}
            link="Shop Now"
            src="/shop"
            gap="0.5"

          >
            We offer the largest product selection in San Jose, which includes the Bay&apos;s most popular brands.
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Voted Best Dispensary"
            // icon={faAwardSimple}
            animatedIcon={verified}
            link="Learn More"
            src="/blog/celebrating-excellence-purple-lotus-voted-best-dispensary-in-northern-california"
            gap="0.5"

          >
            Voted Best Boutique Cannabis Retailer in Northern California by Weedmaps’ "Best of 2024" awards. 
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Knowledgeable Budtenders"
            // icon={faStar}
            animatedIcon={information}
            link="Get in Touch"
            src="/contact"
            gap="0.5"

          >
            Whether you&apos;re a seasoned connoisseur or are new to cannabis, our budtenders can answer any of your questions.
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Reliable Delivery"
            // icon={faTruckFast}
            animatedIcon={deliveryTruck}
            link="Delivery"
            src="/weed-delivery"
            gap="0.5"

          >
            Voted best cannabis delivery service in Silicon Valley, we excel at providing prompt, efficient, and friendly service.
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Outstanding Service"
            // icon={faUserHeadset}
            animatedIcon={support}
            link="Contact Support"
            src="/contact"
            gap="0.5"

          >
            Our dedicated team is always ready to assist you and ensure your experience with us is nothing short of excellent.
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Secure Transactions"
            // icon={faShieldCheck}
            animatedIcon={shield}
            link="Payment Options"
            src="/payment-options"
            gap="0.5"

          >
            We provide multiple payment options, including cash, credit, secure 1-click payments, and have on-site ATMs.
          </Card>
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          <Card
            title="Exclusive Deals"
            // icon={faBadgeDollar}
            animatedIcon={priceTag}
            link="Deals & Specials"
            src="/deals"
            gap="0.5"

          >
            Subscribe for exclusive deals & rewards. We also offer daily deals and discounts for students, seniors, and veterans.
          </Card>
        </SwiperSlide>
        {/* <div className={`${styles.scrollbar} swiper-scrollbar`}></div> */}
      </Swiper>
    </div>
  );

}

