import Link from "next/link";
import Image from "next/image";
import styles from "./CardOld.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { LottieIcon } from "../Lottie/icon";
import { useState } from "react";

export default function CardDeprecated(props) {
  const { img, src, link, icon, alt } = props;
  return (
    <>
      {src ? (
        <Link href={src || "#"}>
          <Content props={props} />
        </Link>
      ) : (
        <Content props={props} />
      )}
    </>
  );
}

function Content({ props }) {
  const {
    title,
    img,
    icon,
    animatedIcon,
    src,
    alt,
    button,
    horizontal,
    contain,
    reverse,
    link,
    gap,
    equalHeight,
    imgTag,
    titleTag,
    center,
    white,
    dark,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className={`${styles.card} ${white ? styles.white : undefined} ${contain ? styles.contain : undefined
          } ${dark ? styles.dark : undefined} ${horizontal ? styles.horizontal : undefined
          } ${reverse && styles.reverse}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {img && (
          <div
            className={`${styles.image} ${equalHeight ? styles.equalHeight : undefined
              }`}
          >
            {imgTag && <div className={styles.imgTag}>{imgTag}</div>}
            <Image
              src={img}
              width={1000}
              height={600}
              alt={alt}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        )}

        <div className={styles.contentWrapper}>
          <div className={styles.content} style={{ gap: `${gap}em` }}>
            {icon && (
              <div className={styles.icon}>
                <FontAwesomeIcon icon={icon} />
              </div>
            )}
            {animatedIcon && (
              <div className={isHovered ? "lottie-hover" : "lottie"}>
                <LottieIcon animatedIcon={animatedIcon} isHovered={isHovered} />
              </div>
            )}
            {title && (
              <div
                className={`${styles.title} ${center ? styles.center : undefined
                  }`}
              >
                {title}
                {titleTag && (
                  <span className={styles.titleTag}>{titleTag}</span>
                )}
              </div>
            )}
            {props.children && (
              <div className={styles.body}>{props.children}</div>
            )}
            {button && (
              <div className="button" data-type="primary" >
                {button}
              </div>
            )}
            {link && (
              <div className={styles.link}>
                {link} <FontAwesomeIcon icon={faArrowRight} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
