import styles from './Skeleton.module.scss'
import SkeletonElement from './SkeletonElement';
import Shimmer from './Shimmer'

export default function SkeletonCard() {
  return ( 
    <div className={styles.wrapper}>
      <div className={styles.hero}>
        <SkeletonElement type="hero"/>
      </div>
      <Shimmer />
    </div>
   );
}
 