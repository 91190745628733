import Link from 'next/link'
import Image from 'next/image'
import styles from './BlogCard.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

export default function BlogCard(props) {
  return (
    <Link 
      href={`/blog/${props.url}`}
      prefetch={false}
    >
      <div className={`${styles.card} ${props.class ? styles[props.class] : ''}`}>
        <div className={styles.card__imageContainer}>
        <Image
          src={props.image}
          alt={`blog post background image for ${props.key || props.id}`}
          fill
          sizes="
          (max-width: 733px) 100vw,
          (max-width: 1087px) 50vw,
            460px"
        />
        </div>
        <div className={styles.card__body}>
          <div className={styles.card__title}>
            {props.title}
          </div>
          <FontAwesomeIcon icon={faArrowRight} />
          {/* <span className={styles.card__category}>
            {props.category}
          </span> */}
        </div>
      </div>
    </Link>
  );
}

