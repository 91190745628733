import { useState } from 'react';
import Link from 'next/link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { faShapes } from '@fortawesome/pro-solid-svg-icons'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { Navigation, Pagination, FreeMode, Scrollbar } from "swiper";

import styles from './CategorySlider.module.scss'
import Button from '../../components/Button/button';

import Flower from '../../public/icons/flower.svg'
import Preroll from '../../public/icons/pre-roll.svg'
import Extract from '../../public/icons/extract.svg'
import Vape from '../../public/icons/vape.svg'
import Edible from '../../public/icons/edible.svg'
import Tincture from '../../public/icons/tincture.svg'
import Topical from '../../public/icons/topical.svg'
import Gear from '../../public/icons/gear.svg'
import Merch from '../../public/icons/merch.svg'
import Leaf from "../../public/icons/flower-2.svg";

export default function Slider({ title }) {

  const [swiper, setSwiper] = useState({});
  const categories = [
   
    // {
    //   category: "all",
    //   href: "/shop",
    //   icon: <Leaf />,
    // },
    {
      category: "flower",
      href: "/shop/flower",
      icon: <Flower />,
    },
    {
      category: "pre-roll",
      href: "/shop/pre-roll",
      icon: <Preroll />,
    },
    {
      category: "extract",
      href: "/shop/extract",
      icon: <Extract />,
    },
    {
      category: "vape",
      href: "/shop/vape",
      icon: <Vape />,
    },
    {
      category: "edible",
      href: "/shop/edible",
      icon: <Edible />,
    },
    {
      category: "tincture",
      href: "/shop/tincture",
      icon: <Tincture />,
    },
    {
      category: "topical",
      href: "/shop/topical",
      icon: <Topical />,
    },
    {
      category: "gear",
      href: "/shop/gear",
      icon: <Gear />,
    },
    {
      category: "merch",
      href: "/shop/merch",
      icon: <Merch />,
    },
  ];

  return (
    <>
      <div>

        <div className={styles.header}>
          <div className={styles.title}>
            {/* <div className={styles.icon}>
              <FontAwesomeIcon icon={faShapes} />
            </div> */}
            <h2>{title}</h2>
          </div>
          <Button type="link" href='/shop' className="sliderLink" prefetch={false} ariaLabel="Shop All">
            <span>shop all</span>
          </Button>
        </div>

        <Swiper
          onInit={(ev) => {
            setSwiper(ev)
          }}
          modules={[Navigation, Pagination, FreeMode]}
          className={styles.categorySwiper}
          touchEventsTarget='wrapper'
          breakpoints={{
            1024: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 14,
            },
            550: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 10,
            }
          }}
          navigation={{
            prevEl: `#category-prev`,
            nextEl: `#category-next`,
            clickable: true,
          }}
        >
           <div className={styles.prev} id={`category-prev`}>
            <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </div>
          <div className={styles.next} id={`category-next`}>
            <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>

          {categories
            .map(category =>
              <SwiperSlide key={category.category} className={styles.slide}>
                <Link href={category.href} passHref>
                  <div className={styles.icon}>{category.icon}</div>
                    <div className={styles.slideTitle}>{category.category}</div>
                </Link>
              </SwiperSlide>
            )
          }

        </Swiper>
      </div>
    </>
  );
}

